@use 'src/css/breakpoint.scss' as *;

.mainWrap {
  display: flex;
  flex-direction: column;
  padding-top: 110px;
  min-height: 100vh;
  background-color: var(--main-dark-color);
  overflow: hidden;

  @include maxMediaWidth(lg) {
    padding-top: 80px;
  }

  @include maxMediaWidth(sm) {
    padding-top: 70px;
  }
}

.wrapperWithBottomMargin {
  padding-bottom: 73px;
}

.layout {
  flex: 1;
}

.defaultLayoutComponentWrapper {
  max-width: 1274px;
  padding: 0 70px;
  margin: 130px auto;

  @include maxMediaWidth(sm) {
    padding: 0;
    margin-left: 15px;
    margin-right: 15px;
  }
}
