@use 'src/css/breakpoint.scss' as *;

.list {
  list-style: none;
  margin: 15px 0 0;
  display: grid;
  gap: 16px;
}

.list-1,
.list-4 {
  grid-template-columns: repeat(2, 1fr);
}

.list-2,
.list-3 {
  grid-template-columns: 1fr;
}

.list-1,
.list-2,
.list-3,
.list-4 {
  @include maxMediaWidth(lg) {
    grid-template-columns: 1fr;
  }

  @include maxMediaWidth(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.title {
  font: var(--font-h11);
  font-weight: var(--weight-medium);
  letter-spacing: 0.04em;
  color: var(--secondary-text-color);
  opacity: 0.55;
}

.titleLink {
  transition:
    color var(--transition),
    opacity var(--transition);
  cursor: pointer;

  &:hover {
    color: var(--footer-title-hover-color);
    opacity: 1;
    text-decoration: none;
  }
}

.titleWrapper {
  display: flex;
  gap: 8px;

  &:hover {
    .arrowIcon {
      color: var(--footer-link-hover-color);
    }
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.arrowIcon {
  transform: rotate(180deg);
  color: var(--footer-link-icon-color);
}

.listLink {
  font: var(--font-h11);
  color: var(--secondary-text-color);
  outline: none;

  &:focus,
  &:hover {
    color: var(--footer-link-hover-color);
    text-decoration: none;
  }
}
