@use 'src/css/breakpoint.scss' as *;

@import '../helper.module.scss';

.links {
  display: grid;
  grid-row-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;

  @include maxMediaWidth(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include maxMediaWidth(sm) {
    grid-template-columns: 1fr;
  }
}

.linksColumn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @include maxMediaWidth(lg) {
    grid-template-columns: 1fr;
  }

  @include maxMediaWidth(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.navList {
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
