@use 'src/css/breakpoint.scss' as *;

@import './helper.module.scss';

.footer {
  background: var(--main-dark-color);

  & hr {
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 30px 0;
  }
}

.wrapLogoAndSocials {
  margin: 0 0 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  gap: 32px;

  @include maxMediaWidth(lg) {
    text-align: center;
  }

  @include maxMediaWidth(sm) {
    flex-direction: column;
    align-items: center;
    gap: 25px;

    &::after {
      position: absolute;
      content: '';
      bottom: -25px;
      left: -20px;
      height: 1px;
      width: 100vw;
      background: var(--color-foreground-alpha-20);
    }
  }
}

.externalLinks {
  display: flex;
  align-items: center;
  flex: 1 1 60%;
  width: 100%;
}

.logo {
  display: inline-block;
  width: 130px;
  outline: none;

  @media (min-width: 834px) {
    width: 140px;
  }
}

.actionsAndInfo {
  display: grid;
  grid-template-columns: 35% 15% 15% 35%;
  gap: 16px;

  @include maxMediaWidth(lg) {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  @include maxMediaWidth(sm) {
    grid-template-columns: 1fr;
  }
}

.bottom {
  height: auto;
  background: var(--main-dark-color);
  padding-bottom: 30px;
}

.copyrights {
  font-size: 1.4rem;
  color: var(--secondary-text-color);
  padding-top: 3px;

  @include maxMediaWidth(sm) {
    display: grid;
  }

  .language {
    white-space: nowrap;
  }
}

.innerWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @include maxMediaWidth(lg) {
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }
}

.partnersBlock {
  background-color: var(--footer-partners-bg-dark-color);
  padding: 25px 0;
  margin: 0 0 35px;
  max-width: 100%;

  @include maxMediaWidth(sm) {
    padding: 11px 0;
  }
}

.socialsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  width: 100%;

  @include maxMediaWidth(sm) {
    justify-content: center;
    gap: 8px;
    position: relative;
    margin-bottom: 5px;
  }
}
