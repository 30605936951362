@use 'src/css/breakpoint.scss' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &.hide {
    display: none;
  }
}

.navigation {
  display: flex;
  align-items: center;
  padding: 16px;

  button {
    cursor: pointer;
    background: none;
    border: none;
    color: var(--header-secondary-text-dark-color);
    transform: rotate(90deg);
  }

  span {
    margin: 0 auto;
    font: var(--font-h7);
    color: var(--header-secondary-text-dark-color);
  }
}

.link {
  margin: 16px auto;
  font: var(--font-header-custom-link);
}

.container {
  overflow-y: auto;
}
