@use 'src/css/breakpoint.scss' as *;

.marquee {
  --marquee-item-width: 255px;
  --marquee-item-height: 55px;
  --marquee-duration: 24s;
  --marquee-items: 4;

  display: flex;
  block-size: var(--marquee-item-height);
  position: relative;
  overflow-x: hidden;
  mask-image: var(--footer-partners-gradient);

  @include maxMediaWidth(sm) {
    --marquee-item-width: 110px;
    --marquee-item-height: 24px;
  }
}

.partnerLink {
  --marquee-item-offset: max(
    calc(var(--marquee-item-width) * var(--marquee-items)),
    calc(100% + var(--marquee-item-width))
  );
  --marquee-delay: calc(
    var(--marquee-duration) / var(--marquee-items) *
      (var(--marquee-items) - var(--marquee-item-index)) * -1
  );

  display: flex;
  align-items: center;
  min-width: 255px;
  width: 255px;
  flex: 0 0 255px;
  text-decoration: none;

  position: absolute;
  inset-inline-start: var(--marquee-item-offset);
  transform: translateX(-50%);
  animation: go linear var(--marquee-duration) var(--marquee-delay, 0s) infinite;

  .image {
    height: 55px;
    width: 100%;
  }

  .link {
    transition: unset;
  }

  @include maxMediaWidth(sm) {
    min-width: 110px;
    flex: 0 0 110px;
    width: 110px;

    .image {
      height: 24px;
    }
  }

  &:visited,
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
    color: inherit;
  }
}

@keyframes go {
  to {
    inset-inline-start: calc(var(--marquee-item-width) * -1);
  }
}
