.link {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  background-color: var(--header-menu-bg-target-link);
  border-radius: 80px;
  font: var(--font-h12);
  color: var(--header-menu-color-target-link);
  height: 40px;
  width: fit-content;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    color: var(--header-menu-color-target-link);
  }
}
