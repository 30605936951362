@use 'src/css/breakpoint.scss' as *;

.wrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  top: 70px;
  left: calc(100% - 55px);
  width: 360px;
  background: var(--mobileNav-bg-dark-color);
  z-index: 3;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 700px;
  border-radius: 8px;

  // closed state
  transform: translateX(-100%);
  overflow: unset;
  opacity: 0;
  pointer-events: none;
  height: 0;
  // closed state

  transition:
    opacity 0.3s ease,
    height 0.3s ease;

  --wrapper-height: calc(100vh - 70px);

  @supports (height: 100dvh) {
    --wrapper-height: calc(100dvh - 70px);
  }

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 8px;
    padding: 1px;
    background: var(--header-menu-gradient);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
  }

  @include maxMediaWidth(sm) {
    top: 70px;
    width: 100%;
    max-height: var(--wrapper-height);
    border-radius: 0;
    left: 0;
    transform: none;

    &::before {
      display: none;
    }
  }

  &.isOpen {
    pointer-events: unset;
    opacity: 1;
    height: var(--wrapper-height);
  }
}

.nav {
  display: block;
  width: 100%;
  height: 100%;
}

.burger {
  position: relative;
  flex: 0 0 22px;
  height: 16px;
  cursor: pointer;
  display: block;
  z-index: 3;
}

.burgerInit {
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &,
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: var(--header-border-color);
  }

  &::before {
    top: -14px;
    transition:
      top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  }

  &::after {
    top: -7px;
    transition:
      top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
      opacity 0.1s linear 0s;
  }
}

.open {
  transform: translate3d(0px, -7px, 0px) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  &::before {
    top: 0;
    transform: rotate(-90deg);
    transition:
      top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s,
      transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
  }

  &:after {
    top: 0;
    opacity: 0;
    transition:
      top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0s,
      opacity 0.1s linear 0.22s;
  }
}
