@use 'src/css/breakpoint.scss' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  color: var(--header-secondary-text-dark-color);
  border: none;
  cursor: pointer;

  &.active svg {
    transform: rotate(180deg);
    color: var(--color-secondary);
  }

  &:hover svg {
    color: var(--color-secondary);
  }

  &.active .iconBackground {
    background-color: var(--header-menu-bg-profile-active);
  }
}

.iconBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 80px;
  background-color: var(--header-menu-bg-profile);
}

.menu {
  display: none;

  &.show {
    display: block;
    transform: translateX(-30%);
    top: 90px;
  }
}

.link {
  margin: 14px 16px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  list-style: none;
  margin-bottom: 4px;

  a {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 16px;
    font: var(--font-h9);
    color: var(--header-secondary-text-dark-color);
    white-space: nowrap;

    &:hover {
      text-decoration: none;
      background-color: var(--header-menu-bg-link-hover);
      border-radius: 8px;
      color: var(--secondary-text-color);
    }
  }
}

.navigation {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;

  button {
    cursor: pointer;
    background: none;
    border: none;
    color: var(--header-secondary-text-dark-color);
    transform: rotate(90deg);
  }

  @include maxMediaWidth(sm) {
    padding: 16px;
  }

  span {
    margin: 0 auto;
    font: var(--font-h9);
    color: var(--header-secondary-text-dark-color);
    white-space: nowrap;
  }
}
