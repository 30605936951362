.wrapper {
  background: var(--header-menu-bg-dark-color);
  top: 80px;
  transform: translateX(-40%);
  z-index: 2;
  border-radius: 8px;
  position: absolute;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 8px;
    padding: 1px;
    background: var(--header-menu-gradient);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
  }
}
