@use 'src/css/breakpoint.scss' as *;

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 6px 0;

  &.column {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  @include maxMediaWidth(lg) {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  li::before {
    display: none;
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 8px 32px;
  font: var(--font-h11);
  color: var(--header-secondary-text-dark-color);
  cursor: pointer;

  &:hover {
    background-color: var(--header-menu-bg-link-hover);
    border-radius: 8px;
    color: var(--secondary-text-color);
    text-decoration: none;
  }

  @include maxMediaWidth(lg) {
    padding: 8px 16px;
  }
}

.title {
  margin-top: 2px;
  opacity: 0.5;
  color: var(--header-secondary-text-dark-color);

  &.noSubtitle {
    opacity: 1;

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    text-decoration: none;
    opacity: 0.5;
    color: var(--header-secondary-text-dark-color);
  }
}

.info {
  display: flex;
  flex-direction: column;

  a,
  span {
    white-space: nowrap;
  }

  a,
  a:hover {
    text-decoration: none;
    color: var(--header-secondary-text-dark-color);
  }

  > :nth-child(1) {
    opacity: 1;
    color: var(--header-secondary-text-dark-color);
  }

  > :nth-child(2) {
    margin-top: 2px;
    opacity: 0.5;
  }
}
