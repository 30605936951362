@use 'src/css/breakpoint.scss' as *;

.wrap {
  display: flex;
  align-items: center;
  height: 90px;

  @include maxMediaWidth(lg) {
    display: none;
  }
}

.nav {
  display: flex;
  align-items: center;
  gap: 30px;
  height: 90px;
  width: auto;
  margin-right: 30px;
}
