@use 'src/css/breakpoint.scss' as *;

.wrap {
  flex: 1 1 auto;
  max-width: 1274px;
  width: 100%;
  padding-left: 70px;
  padding-right: 70px;
  margin-left: auto;
  margin-right: auto;

  @include maxMediaWidth(lg) {
    padding-left: 55px;
    padding-right: 55px;
  }

  @include maxMediaWidth(sm) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
