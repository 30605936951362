@use 'src/css/breakpoint.scss' as *;

.link {
  outline: none;
}

.socialIcon {
  filter: brightness(0) saturate(100%) invert(100%) sepia(97%) saturate(0%) hue-rotate(226deg)
    brightness(117%) contrast(101%);

  @include maxMediaWidth(sm) {
    width: 40px;
    height: 40px;
  }

  &:hover {
    filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(6926%) hue-rotate(315deg)
      brightness(94%) contrast(104%);
  }
}
