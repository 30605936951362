@use 'src/css/breakpoint.scss' as *;

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.hide {
    display: none;
  }
}

.list li:before {
  display: none;
}

.link {
  margin: 16px auto 40px auto;
  font: var(--font-header-custom-link);
}

.separator {
  height: 1px;
  background-color: var(--header-nav-separator);
  width: 90%;
  margin: 20px auto 0;
}

.linkWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  color: var(--header-secondary-text-dark-color);
  padding: 8px 16px;
  font: var(--font-h15);

  &:hover {
    background-color: var(--header-menu-bg-link-hover);
    border-radius: 8px;
    color: var(--secondary-text-color);

    a {
      text-decoration: none;
    }
  }

  a {
    color: var(--header-secondary-text-dark-color);
  }
}
