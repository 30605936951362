@use 'src/css/breakpoint.scss' as *;

.pseudoLink {
  display: flex;
  align-items: center;
  height: 100%;
  font: var(--font-h9);
  color: var(--header-color);
  cursor: pointer;
  white-space: nowrap;

  &:active,
  &:hover {
    color: var(--header-link-hover-color);
  }

  @include maxMediaWidth(lg) {
    justify-content: space-between;
    height: fit-content;
    padding: 8px 16px;
    margin-right: 0;
    font: var(--font-h15);

    &:hover {
      background-color: var(--header-menu-bg-link-hover);
      border-radius: 8px;
      color: var(--secondary-text-color);
      text-decoration: none;
    }
  }
}

.wrapArrow {
  margin-left: 5px;
  width: 10px;

  &.visibility svg {
    transform: rotate(180deg);
  }

  @include maxMediaWidth(lg) {
    svg,
    &.visibility svg {
      transform: rotate(270deg);
    }
  }
}

.menu {
  display: none;

  &.show {
    display: block;
  }

  &.withoutIcon {
    left: 50%;
    transform: translateX(50%);

    .list {
      display: flex;
      flex-direction: column;
    }

    .link {
      padding: 8px 16px;
    }
  }
}

.title {
  display: block;
  font: var(--font-h7);
  color: var(--header-secondary-text-dark-color);
  padding: 16px 0 0 32px;
}

.link {
  margin: 20px auto;
}
