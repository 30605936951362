@use 'src/css/breakpoint.scss' as *;

.header {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  z-index: 4;
  height: 112px;

  @include maxMediaWidth(lg) {
    height: 80px;
  }

  @include maxMediaWidth(sm) {
    height: 70px;

    &.menuOpened {
      background: var(--header-bg-dark-color);
    }
  }
}

.wrapLogo {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 0 0 186px;
  outline: none;

  &:focus {
    opacity: 0.9;
  }

  @include maxMediaWidth(lg) {
    z-index: 4;
  }

  @include maxMediaWidth(sm) {
    flex: 0 0 147px;
  }

  img {
    vertical-align: top;
  }
}

.wrapInner {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: calc(100% - 140px);

  @include maxMediaWidth(lg) {
    min-width: calc(100% - 110px);
  }

  @include maxMediaWidth(sm) {
    min-width: calc(100% - 30px);
  }
}
