@use 'src/css/breakpoint.scss' as *;

.link {
  display: flex;
  align-items: center;
  height: 100%;
  font: var(--font-h9);
  color: var(--secondary-text-color);
  outline: none;
  white-space: nowrap;

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
    color: var(--header-link-hover-color);
  }

  @include maxMediaWidth(lg) {
    justify-content: space-between;
    height: fit-content;
    padding: 8px 16px;
    margin-right: 0;
    font: var(--font-h15);

    &:hover {
      background-color: var(--header-menu-bg-link-hover);
      border-radius: 8px;
      color: var(--secondary-text-color);
    }
  }
}
