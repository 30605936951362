@use 'src/css/breakpoint.scss' as *;

.paymentTypesWrap {
  display: flex;
  align-items: center;
  gap: 15px;

  @include maxMediaWidth(sm) {
    flex-direction: column;
  }
}

.imgWrap {
  position: relative;
  object-fit: contain;
  width: 68px;
  height: 20px;
  width: auto;

  .image {
    opacity: 0.5;
    object-position: center;
    object-fit: contain;
  }

  @include maxMediaWidth(sm) {
    width: 42px;
    height: 12px;
  }
}

.title {
  font: var(--font-h10);
  color: var(--light-dark-color);
  text-wrap: nowrap;

  @include maxMediaWidth(sm) {
    font: var(--font-h12);
  }
}

.paymentTypeSwiper {
  width: 344px;

  @include maxMediaWidth(sm) {
    width: 198px;
  }
}
